import React from "react";
import ProfilePic from "../Assets/john-doe-image.png";
import { AiFillStar } from "react-icons/ai";
import { useTranslation } from "react-i18next";

const Testimonial = () => {
  const { t } = useTranslation();

  return (
    <div className="work-section-wrapper">
    <div className="work-section-top">
      <p className="primary-subheading">{t('testimonials.section_title')}</p>
      <h1 className="primary-heading">{t('testimonials.section_heading')}</h1>
      <p className="primary-text">{t('testimonials.section_text')}</p>
    </div>
    <div className="testimonial-section-bottom">
      <p>{t('testimonials.review1.text')}</p>
      <div className="testimonials-stars-container">
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
      </div>
      <h2>{t('testimonials.review1.author')}</h2>
    </div>
    <div className="testimonial-section-bottom">
      <p>{t('testimonials.review2.text')}</p>
      <div className="testimonials-stars-container">
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
      </div>
      <h2>{t('testimonials.review2.author')}</h2>
    </div>
    <div className="testimonial-section-bottom">
      <p>{t('testimonials.review3.text')}</p>
      <div className="testimonials-stars-container">
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
      </div>
      <h2>{t('testimonials.review3.author')}</h2>
    </div>
    <div className="testimonial-section-bottom">
      <p>{t('testimonials.review4.text')}</p>
      <div className="testimonials-stars-container">
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
        <AiFillStar />
      </div>
      <h2>{t('testimonials.review4.author')}</h2>
    </div>
  </div>
  );
};

export default Testimonial;

import React from "react";
import Slider from "react-slick";
import BannerBackground from "../Assets/home-banner-background.png";
import BannerImage1 from "../Assets/mhm-tabas-kilif.png";
import BannerImage2 from "../Assets/mhmtabas33.png";
import BannerImage4 from "../Assets/mhmtabas444.png";
import BannerImage3 from "../Assets/mhm-tabas.png";
import BannerImage5 from "../Assets/mhm-tabas-baston.png";
import Navbar from "./Navbar";
import { FiArrowRight } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const Home = () => {
  const { t } = useTranslation();

  function handleClick() {
    window.location.href = "https://wa.me/905322442306?text=Merhaba%20Tabas%20satın%20almak%20istiyorum";
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="home-container">
      <Navbar />
      <div className="home-banner-container">
        <div className="home-bannerImage-container">
          <img src={BannerBackground} alt="" />
        </div>
        <div className="home-text-section">
          <h1 className="primary-heading">
            {t('home.banner_heading')}
          </h1>
          <p className="primary-text">
            {t('home.banner_text')}
          </p>
          <button className="secondary-button" onClick={handleClick}>
            {t('home.order_button')} <FiArrowRight />{" "}
          </button>
          <p className="primary-text">
            {t('home.price_text')}
          </p>
        </div>
        <div className="home-image-section">
          <Slider {...settings}>
            <div>
              <img src={BannerImage3} alt="Banner 2" />
            </div>
            <div>
              <img src={BannerImage5} alt="Banner 2" />
            </div>
            <div>
              <img src={BannerImage2} alt="Banner 2" />
            </div>
            <div>
              <img src={BannerImage4} alt="Banner 4" />
            </div>
            <div>
              <img src={BannerImage1} alt="Banner 1" />
            </div>
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default Home;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const selectedLanguage = i18n.language === 'tr' ? 'Türkçe' : i18n.language === 'en' ? 'English' : 'العربية';

  return (
    <DropdownButton id="dropdown-basic-button" title={selectedLanguage}>
      <Dropdown.Item onClick={() => changeLanguage('tr')}>Türkçe</Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
      <Dropdown.Item onClick={() => changeLanguage('ar')}>العربية</Dropdown.Item>
    </DropdownButton>
  );
};

export default LanguageSwitcher;

import "./App.css";
import Home from "./Components/Home";
import About from "./Components/About";
import About2 from "./Components/About2";
import Work from "./Components/Work";
import Testimonial from "./Components/Testimonial";
import Contact from "./Components/Contact";
import Footer from "./Components/Footer";
import Video from "./Components/Video";
import LanguageSwitcher from "./Components/LanguageSwitcher";
import 'bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <div className="App">
      <LanguageSwitcher />
      <Home />
      <About2 />
      <About />
      {/* <Work /> */}
      <Testimonial />
      {/* <Contact /> */}
      <Footer />
    </div>
  );
}

export default App;

import React from "react";
import AboutBackgroundImage from "../Assets/sitetabas.jpg";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";

const About2 = () => {
  const { t } = useTranslation();
  return (
    <div className="about-section-container">
          <div className="about-section-text-container">
            <p className="primary-subheading">{t('about.portability')}</p>
            <h1 className="primary-heading">{t('about.small_size')}</h1>
            <p className="primary-text">{t('about.foldable_design')}</p>
            <p className="primary-text">{t('about.easy_to_carry')}</p>
          </div>
          <div className="about-section-video-container">
            <video width="100%" height="auto" controls>
              <source src="/sitetabasvideo.mp4" type="video/mp4" />
              Tarayıcınız video etiketini desteklemiyor.
            </video>
          </div>
        </div>
  );
};

export default About2;

import React from "react";
import Slider from "react-slick";
import AboutBackground from "../Assets/about-background.png";
import AboutBackgroundImage from "../Assets/sitetabas.jpg";
import { BsFillPlayCircleFill } from "react-icons/bs";
import BannerImage1 from "../Assets/cizim1.png";
import BannerImage2 from "../Assets/cizim2.png";
import BannerImage3 from "../Assets/cizim3.png";
import BannerImage4 from "../Assets/cizim4.png";
import { useTranslation } from "react-i18next";

const About = () => {
  const { t } = useTranslation();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <div className="about-section-container">
      <div className="about-background-image-container">
        <img src={AboutBackground} alt="" />
      </div>
      <div className="home-image-section">
        <Slider {...settings}>
          <div>
            <img src={BannerImage1} alt="Banner 1" />
          </div>
          <div>
            <img src={BannerImage2} alt="Banner 2" />
          </div>
          <div>
            <img src={BannerImage3} alt="Banner 3" />
          </div>
          <div>
            <img src={BannerImage4} alt="Banner 4" />
          </div>
        </Slider>
      </div>
      <div className="about-section-text-container">
        <p className="primary-subheading">{t('about.usage')}</p>
        <h1 className="primary-heading">
          {t('about.heading')}
        </h1>
        <p className="primary-text">
          {t('about.text1')}
        </p>
        <p className="primary-text">
          {t('about.text2')}
        </p>
      </div>
    </div>
  );
};

export default About;
